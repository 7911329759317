import React from 'react'

import { CommonFieldProps } from '.'

import FormGroup from 'components/common/formGroup'
import TranslatedTextInput from 'components/common/translatedTextInput'
import { useField } from 'formik'

export const TranslatedTextField: React.FC<CommonFieldProps> = ({
  label,
  className,
  required = false,
  hideFormGroup = false,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props)

  return (
    <FormGroup className={className} label={label} required={required} hideFormGroup={hideFormGroup} {...props}>
      <TranslatedTextInput
        className='form-control'
        values={field.value || {}}
        onChange={(locale, translation) => setValue({ ...field.value, [locale]: translation })}
        placeholder=''
      />
      {error && <div className='text-danger'>{error}</div>}
    </FormGroup>
  )
}
