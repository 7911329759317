import React from 'react'

import StoreHome from './components/StoreHome'

import { render } from 'react-dom'

document.addEventListener('DOMContentLoaded', () => {
  const root = document.getElementById('store-root')

  render(<StoreHome logoUrl={root.dataset.logoUrl} />, root)
})
