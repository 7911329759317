import { MutableRefObject, useEffect, useRef } from 'react'

export const useClickOutside = <T extends HTMLElement>(callback: () => void, enabled = true): MutableRefObject<T> => {
  const ref = useRef<T>()

  const handleClick = (e: MouseEvent) => {
    if ((e.target as HTMLElement).isConnected && ref.current && !ref.current.contains(e.target as Node)) {
      callback()
    }
  }

  useEffect(() => {
    enabled && document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  return ref
}
